import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Router } from '@angular/router';
import { eFlowMode, eWalletGetMode } from '@common/api/auth';
import { IKycDetailedStatusResponse, eVerificationLevel } from '@common/api/kyc';
import { IUserFullInfo, IUserRoleDetailed } from '@common/api/user';
import { ISetWalletParams, IWalletRes } from '@common/api/wallets';
import { BehaviorSubject, ReplaySubject, combineLatest, firstValueFrom } from 'rxjs';
import { environment } from '@environments/environment';
import { IUserProfile } from '@common/api/keycloack';
import { WalletService } from '@services/wallet/wallet.service';
import { ReferralService } from "@services/referral/referral.service";

@Injectable({
    providedIn: 'root'
})
export class AuthService
{
    public authState$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    public userInfo$: BehaviorSubject<IUserFullInfo | undefined> = new BehaviorSubject<IUserFullInfo | undefined>(undefined);

    private authState: boolean = false;
    private userInfo: IUserFullInfo | undefined;

    constructor(
        private http: HttpClient,
        private walletService: WalletService,
        @Inject(DOCUMENT) private document: Document,
        private router: Router,
        @Inject(PLATFORM_ID) private platform: Object,
        private referral: ReferralService
    )
    {
        if (isPlatformBrowser(this.platform) && !this.authState)
        {
            this.check();
        }

        combineLatest([
            this.authState$,
            this.userInfo$
        ]).subscribe(([
            authState,
            userInfo
        ]) =>
        {
            this.authState = authState;
            this.userInfo = userInfo;
        });
    }

    public get LoginState(): boolean
    {
        return this.authState;
    }

    public get UserInfo(): IUserFullInfo | undefined
    {
        return this.userInfo;
    }

    public async getLoginUrl(
        redirectUrl?: string,
        flow: eFlowMode = eFlowMode.WEB2_WEB3_SIGN_IN,
        walletGetMode: eWalletGetMode = eWalletGetMode.WALLET_GET_FAST,
    ): Promise<string>
    {
        const callBackUrl = `${ location.origin }/auth/callback`;
        const getAuthFlowEndpoint = `${ environment.api_host }/keycloak/get-url`;
        const getAuthUrl = new URL(getAuthFlowEndpoint);
        const refCode = this.referral.getCode();

        redirectUrl ?
            getAuthUrl.searchParams.append('redirectTo', redirectUrl) :
            getAuthUrl.searchParams.append('redirectTo', encodeURIComponent(location.href));
        getAuthUrl.searchParams.append('flow_mode', String(flow));
        getAuthUrl.searchParams.append('wallet_get_mode', String(walletGetMode));
        getAuthUrl.searchParams.append('callbackUrl', callBackUrl);

        let authUrl = await firstValueFrom(
            this.http.get(getAuthUrl.href, { responseType: 'text' })
        );

        if (flow)
        {
            authUrl += `&flow_mode=${ flow }`;
        }
        else
        {
            authUrl += `&flow_mode=${ eFlowMode.WEB2_WEB3_SIGN_IN }`;
        }

        if (refCode)
        {
            authUrl += `&ref_code=${ refCode }`;
        }

        authUrl += `&wallet_chains=${ environment.activeChainId }`;

        authUrl += `&idp_show=${environment.idp_show}`;

        return authUrl;
    }

    public async processCallback(params: Record<string, any>)
    {
        try
        {
            const url = new URL(`${ environment.api_host }/keycloak/callback`);
            for (const paramsItem in params)
            {
                url.searchParams.set(paramsItem, params[ paramsItem ]);
            }

            await firstValueFrom(this.http.get(url.toString(), { withCredentials: true }));
            await this.check();
        }
        catch (e)
        {
            throw new Error('Unauthorized');
        }
    }

    public async logout()
    {

        try
        {
            await firstValueFrom(this.http.get('/keycloak/logout'));
            this.authState$.next(false);
            this.userInfo$.next(undefined);
        }
        catch (e)
        {
            this.authState$.next(false);
            this.userInfo$.next(undefined);
        }

        if (this.document.location.pathname.includes('platform'))
        {
            this.router.navigate([ '/' ]);
        }

    }

    public async check()
    {
        try
        {
            const authState = await firstValueFrom(
                this.http.get(`/keycloak/isLoggedIn`, { withCredentials: true, responseType: 'text' })
            );

            const state = String(authState) === 'true';

            if (state)
            {
                const [
                    userProfile,
                    userDetails,
                    kycInfo
                ] = await Promise.all(
                    [
                        firstValueFrom(
                            this.http.get('/keycloak/profile')
                        ) as Promise<IUserProfile>,
                        firstValueFrom(
                            this.http.get('/api/users/me?populate[0]=role')
                        ) as Promise<IUserRoleDetailed>,
                        firstValueFrom(this.http.get<IKycDetailedStatusResponse>('/api/kyc/status'))
                    ]
                );

                const wallet = await this.processWallet();

                if (!wallet?.current) throw new Error('Wallet is not defined');

                const userInfo: IUserFullInfo = {
                    ...userProfile,
                    wallets: [wallet.current],
                    wallet: wallet.current,
                    username: userDetails.username,
                    role: userDetails.role.id,
                    phone: userDetails.phone,
                    status: userDetails.status,
                    fullName: userDetails.fullName,
                    wallet_source: wallet.source,
                    userWallets: wallet.verified,
                    kycInfo,
                };

                const refCode = this.referral.getCode();
                if (userInfo?.referral_parent_ref_code === refCode)
                {
                    this.referral.removeCode();
                }

                this.userInfo$.next(userInfo);
                this.referral.fetch();
            }

            this.authState$.next(state);
        }
        catch (e: any)
        {
            console.error(e);
            this.authState$.next(false);
        }
    }

    public async startKyc(upgrade: boolean = false): Promise<void>
    {
        let endpoint = `/api/kyc/flow-url`;
        endpoint += `?redirect_url=${ location.origin }/platform`;

        if (upgrade)
        {
            endpoint += `&verification_level=${ eVerificationLevel.kyc_full }`;
        }

        const url = await firstValueFrom(
            this.http.get(
                endpoint,
                { responseType: 'text' }
            )
        );

        location.href = url;
    }

    private async processWallet(): Promise<IWalletRes>
    {
        const walletInfo = await this.walletService.getWallet();
        return walletInfo;
    }
}
